export const fetchCountryFromIP = async () => {
    try {
        // Check if IP data is already in sessionStorage
        const storedIPData = sessionStorage.getItem('ipData');
        if (storedIPData) {
            return JSON.parse(storedIPData);
        }

        // Fetch new IP data if not in sessionStorage, including the API key in the request
        const apiKey = process.env.REACT_APP_IPAPI_KEY;
        const response = await fetch(`https://ipapi.co/json/?key=BjCgRLnHUBxfYHM5qcmCX6UhrPxRnrYqVuo3G545cLlALdMlyp`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const ipData = await response.json();

        // Store the fetched data in sessionStorage
        sessionStorage.setItem('ipData', JSON.stringify(ipData));

        return ipData;
    } catch (error) {
        console.error('Error fetching IP data:', error);
        return null; // or a default value
    }
};
