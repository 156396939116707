import React, { useState, useEffect, useContext } from 'react';
import CurrentCityContext from '../../CurrentCityContext';
import { Link } from 'react-router-dom'; 
import { fetchCountryFromIP } from '../../utils/fetchCountryFromIP';
import headerLogoUSA from '../../assets/images/resources/logo-usa.png';
import headerLogoCanada from '../../assets/images/resources/logo-canada.png';
import headerLogo from '../../assets/images/resources/logo.png';
import backgroundImage from '../../assets/images/shapes/main-header__top-pattern-bg.png';
import { MdLocalPhone } from "react-icons/md";
import { FaLongArrowAltRight } from "react-icons/fa";
import phoneLogo from '../../assets/images/icon/phone-icon-2.png';

function StickyHeaderSlim({ cityData, locationInfo }) {
    const { currentCity } = useContext(CurrentCityContext);
    const [country, setCountry] = useState('');

    useEffect(() => {
        fetchCountryFromIP().then(ipData => {
            if (ipData) {
                setCountry(ipData.country_name);
            }
        });
    }, []);

    const renderLogo = () => {
        if (country === 'United States') {
            return <img src={headerLogo} width="220" alt="UltaMovers USA" />;
        } else if (country === 'Canada') {
            return <img src={headerLogo} width="220" alt="UltaMovers Canada" />;
        }
        // Default logo if country is neither USA nor Canada, or if not yet determined
        return <img src={headerLogo} width="200" alt="UltaMovers Logo" />;
    };

    // Function to create URL with city prefix
    const createCityLink = (path) => {
        return currentCity ? `/${currentCity}${path}` : path;
    };

    return (
    <>
        <header className="main-header main-header--one">
            <div className="main-header__top-sticky"
                style={{
                    padding: '10px 0',
                }}
            >
                <div className="top-border">
                </div>
                <div className="bottom-border"></div>
                    <div className="main-header__top-pattern-bg"
                    style={{
                        backgroundImage: {backgroundImage}
                    }}
                    >
                </div>
                <div className="container">
                    <div className="main-header__top-inner">
                        <div className="main-header__top-left">
                            <div className="main-header__logo">
                                    {/* <Link to={createCityLink("/")}> */}
                                        {renderLogo()}
                                    {/* </Link> */}
                            </div>
                        </div>
                        <div className="main-header__top-right">
                            <ul className="list-unstyled main-header__address-list">
                            <li>
                                    <div className="content">
                                        <p className="oswald-font"
                                            style={{
                                                fontSize: '24px',
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                textTransform: 'uppercase',
                                                marginBottom: '-5px',
                                                color: '#4e4e4e',
                                            }}
                                        >
                                            GET A FREE ESTIMATE TODAY
                                        </p>
                                        <h4 className="oswald-font"
                                            style={{
                                                fontSize: '36px',
                                                fontWeight: 'bold',
                                                lineHeight: '1.2',
                                                color: '#4e4e4e',
                                            }}
                                        >
                                            <Link 
                                                to={'tel:'+locationInfo.tracking_phone}
                                                style={{
                                                    color: '#4e4e4e',
                                                }}
                                            >
                                                CALL: {locationInfo.tracking_phone}
                                            </Link>
                                            <img 
                                                src={phoneLogo} 
                                                alt=""
                                                style={{
                                                    marginLeft: '10px',
                                                }}
                                            />
                                        </h4>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <div className="mobile-sticky-cta">
            <Link to={createCityLink("/get-quote")} className="cta-column">
                <span className="cta-icon"><FaLongArrowAltRight /></span>
                <span className="cta-text">GET QUOTE</span>
            </Link>
            <Link to={'tel:' + locationInfo.tracking_phone} className="cta-column">
                <span className="cta-icon"><MdLocalPhone /></span>
                <span className="cta-text">CALL/SMS</span>
            </Link>
        </div>

    </>
    );
}

export default StickyHeaderSlim;