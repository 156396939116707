import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { handlePhoneChange, handleNameChange, handlePostalChange, handleZipChange, handleMessageChange, handleEmailChange, handleSubmit } from '../../utils/formHandlers';
import { fetchCountryFromIP } from '../../utils/fetchCountryFromIP';
import { FaArrowRight } from 'react-icons/fa';
import { MdLocalPhone } from "react-icons/md";

function HeroFormLander({ cityData, locationInfo }) {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [zip, setZip] = useState('');
    const [zipTo, setZipTo] = useState('');
    const [message, setMessage] = useState('');
    const [country, setCountry] = useState('');
    
    // Error states
    const [errors, setErrors] = useState({});

    useEffect(() => {
        fetchCountryFromIP().then(ipData => {
            if (ipData) {
                setCountry(ipData.country_name);
            }
        });
    }, []);

    const validateForm = () => {
        const newErrors = {};
        if (!name) newErrors.name = "Name is required";
        if (!phone || phone.length < 10) newErrors.phone = "Phone number is required";
        if (!email || !email.includes("@")) newErrors.email = "A valid email is required";
        if (!postalCode && country === 'Canada') newErrors.postalCode = "Postal Code is required";
        if (!zip) newErrors.zip = "Moving From Zip is required";
        if (!zipTo) newErrors.zipTo = "Moving To Zip is required";
        if (!message) newErrors.message = "Message is required";
        return newErrors;
    };

    const formSubmitHandler = (event) => {
        event.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            const formState = { name, phone, email, postalCode, zip, zipTo, message };
            handleSubmit(formState, cityData, 
                () => {
                    navigate('/thankyou');
                }, 
                (error) => {
                    console.error("Error adding lead: ", error);
                }
            );
        } else {
            setErrors(formErrors);
        }
    };

    return (
        <form 
            onSubmit={formSubmitHandler} 
            className="main-slider__form comment-form-2-validated" 
            noValidate="noValidate"
        >
            <div className="row">
                <div className="col-xl-6">
                    <div className="main-slider__form-input-box">
                        <input 
                            type="text" 
                            placeholder="Name:" 
                            name="name" 
                            value={name}
                            autoComplete="name"
                            onChange={(e) => handleNameChange(setName, e)}
                            maxLength={20}
                            required
                        />
                        {errors.name && <span className="error">{errors.name}</span>}
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="main-slider__form-input-box">
                        <input 
                            type="text" 
                            placeholder="Phone:" 
                            name="phone" 
                            autoComplete="tel"
                            value={phone}
                            onChange={(e) => handlePhoneChange(setPhone, e)}
                            maxLength={12}
                            required
                        />
                        {errors.phone && <span className="error">{errors.phone}</span>}
                    </div>
                </div>
                <div className="col-xl-12">
                    <div className="main-slider__form-input-box">
                        <input 
                            type="email" 
                            placeholder="Email:" 
                            name="email" 
                            autoComplete="email"
                            value={email}
                            onChange={(e) => handleEmailChange(setEmail, e)}  
                            required
                        />
                        {errors.email && <span className="error">{errors.email}</span>}
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="main-slider__form-input-box">
                        {country === 'Canada' && (
                        <input 
                            type="text" 
                            placeholder="Moving From Postal:" 
                            name="postalCode" 
                            autoComplete="postal-code"
                            value={postalCode.toUpperCase()}
                            onChange={(e) => handlePostalChange(setPostalCode, e)}
                            maxLength={6}
                            required
                        /> 
                        )}
                        {errors.postalCode && <span className="error">{errors.postalCode}</span>}
                        {country === 'USA' && (
                        <input 
                            type="text" 
                            placeholder="Moving From Zip:" 
                            name="zip" 
                            autoComplete="postal-code"
                            value={zip}
                            onChange={(e) => handleZipChange(setZip, e)}
                            maxLength={5}
                            required
                        />
                        )}
                        {errors.zip && <span className="error">{errors.zip}</span>}
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="main-slider__form-input-box">
                        {country === 'Canada' && (
                            <input 
                                type="text" 
                                placeholder="Moving To Postal:" 
                                name="postalCodeTo" 
                                autoComplete="postal-code"
                                value={zipTo.toUpperCase()}
                                onChange={(e) => handlePostalChange(setZipTo, e)}  
                                maxLength={6}
                                required
                            /> 
                        )}
                        {country === 'USA' && (
                        <input 
                            type="text" 
                            placeholder="Moving To Zip:" 
                            name="zipTo" 
                            autoComplete="postal-code"
                            value={zipTo}
                            onChange={(e) => handleZipChange(setZipTo, e)}  
                            maxLength={5}
                            required
                        />
                        )}
                        {errors.zipTo && <span className="error">{errors.zipTo}</span>}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="main-slider__form-input-box text-message-box">
                        <textarea 
                            name="message" 
                            placeholder="Describe your moving needs:" 
                            value={message} 
                            autoComplete="off"
                            onChange={(e) => handleMessageChange(setMessage, e)}
                            required
                        />
                        {errors.message && <span className="error">{errors.message}</span>}
                    </div>
                    <div className="main-slider__form-btn-box">
                        <button 
                            type="submit" 
                            className="thm-btn main-slider__form-btn"
                            style={{
                                fontSize: '1.1rem',
                                background: 'black',
                                border: '6px solid yellow',
                            }}
                        >
                            Get Your Moving Quote
                            &nbsp;
                            <FaArrowRight />
                        </button>
                    </div>
                </div>
                <hr 
                    style={{
                        width: '100%',
                        marginTop: '20px',
                    }}
                />
                <p
                    style={{
                        marginTop: '-20px',
                        fontFamily: "Roboto, sans-serif",
                        fontSize: '1.2rem',
                        lineHeight: '1.3em',
                    }}
                >
                    <br />
                    <span
                        style={{
                            fontSize: '1.5rem',
                            marginRight: '10px',
                        }}
                    >
                        <MdLocalPhone />
                    </span>
                    Call anytime {locationInfo.tracking_phone} 
                </p>
            </div>
        </form>
    );
}

export default HeroFormLander;
